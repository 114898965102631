import React, {useEffect, useRef, useState} from 'react';
import {Button, Form, Modal} from 'react-bootstrap';
import {fetchDataFromAPI} from '../../utils/api';
import {getUser} from '../../utils/constants';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import moment from 'moment/moment';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Processing from '../../components/Processing/Processing';
import {useNavigate} from 'react-router-dom';
const AddEvents = ({show, handleClose, courtData, data, court_id}) => {
  console.log('courtData', courtData);

  // // Combine the timeSlot arrays and get unique values
  // const combinedTimeSlots = [
  //   ...new Set(courtData?.event.flatMap((item) => item.timeSlot)),
  // ];

  const [urlEvent, setURLEvent] = useState(null);
  const [file, setFile] = useState(null);
  const [courtAllData, setAllCourt] = useState([]);

  const matchedObject = courtAllData.find((item) => item?._id === court_id);

  const [timeSlots, setTimeSlots] = useState([]);

  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    getCourt();
    getTimeSlots();
  }, []);

  useEffect(() => {
    if (data) {
      setLoading(true);
      eventinfo.setFieldValue('title', data?.title);
      eventinfo.setFieldValue('info', data?.info);
      eventinfo.setFieldValue('date', moment(data?.date).format('YYYY-MM-DD'));
      const isMatch = data?.courtEvent.some((id) => id === court_id);
      eventinfo.setFieldValue('courtEvent', isMatch);
      eventinfo.setFieldValue('timeSlots', data?.timeSlot);
      eventinfo.setFieldValue('sendWebook', data?.event);
      eventinfo.setFieldValue(
        'timeSlotsCourt',
        data?.court_id?.map((item) => item?._id),
      );

      setLoading(false);
    }
  }, [data]);

  const getCourt = () => {
    const user = JSON.parse(getUser());

    fetchDataFromAPI('Court/names', 'get', ' ', user?.authToken)
      .then((res) => {
        setAllCourt(res?.data);
        console.log('res ---->', res);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const getTimeSlots = () => {
    const user = JSON.parse(getUser());
    fetchDataFromAPI('timeslot', 'get', '', user?.authToken)
      .then((res) => {
        console.log('timeslot res: ', res?.data);
        setTimeSlots(res?.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log('timeslot error: ', error);
      });
  };

  const validationSchemaEvent = Yup.object().shape({
    title: Yup.string().required('Please enter  title'),
    info: Yup.string().required('Please enter  info'),
    date: Yup.string().required('Please enter date'),
    courtEvent: Yup.mixed().notRequired(),
    timeSlots: Yup.array()
      .min(1, 'Please select time slots')
      .required('Please select time slots'),
    sendWebook: Yup.boolean().notRequired(),
  });

  const handleEditorChangeEvent = (html) => {
    eventinfo.setFieldValue('info', html);
  };

  const eventinfo = useFormik({
    initialValues: {
      title: '',
      info: '',
      date: '',
      courtEvent: [],
      timeSlots: [],
      sendWebook: '',
      timeSlotsCourt: [],
    },
    validationSchema: validationSchemaEvent,
    onSubmit: async (values) => {
      console.log('values', values);
      const user = JSON.parse(getUser());

      // if (values?.timeSlots?.length === 0) {
      //   toast.error('Please select at least one time slot.', {
      //     position: toast.POSITION.TOP_RIGHT,
      //   });
      //   return;
      // }
      const body = {
        image: urlEvent ? urlEvent : data?.image,
        title: values.title,
        info: values.info,
        date: values.date,
        courtEvent: data
          ? values?.courtEvent
            ? data?.courtEvent && !new Set(data?.courtEvent).has(court_id)
              ? [court_id, ...data?.courtEvent] // Add court_id if it's not already present
              : data?.courtEvent // Keep existing courtEvent if court_id is present
            : (data?.courtEvent || [])?.filter((id) => id !== court_id) // Remove court_id if values.courtEvent is false
          : [court_id], // For new event, initialize courtEvent with court_id
        timeSlot: values.timeSlots,
        event: values.sendWebook ? values.sendWebook : false,
        court_id: values?.timeSlotsCourt,
        ...(data ? {} : {host_id: court_id}),
      };

      console.log('body', body);

      if (data) {
        fetchDataFromAPI(
          `event/updateEvent?id=${data?._id}`,
          'put',
          body,
          user?.authToken,
        )
          .then((res) => {
            console.log('res', res);
            toast.success('Event edit successfully ✅', {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });
            handleClose();
            eventinfo.resetForm();
            navigate('/courts');

            // window.location.reload();
          })
          .catch((error) => {
            console.log('error', error);
          });
      } else {
        fetchDataFromAPI(
          `event/createEvent?court_id=${court_id}`,
          'post',
          body,
          user?.authToken,
        )
          .then((res) => {
            console.log('res', res);
            toast.success('Event created successfully ✅', {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });
            handleClose();
            navigate('/courts');

            // window.location.reload();
          })
          .catch((error) => {
            console.log('error', error);
          });
      }
    },
  });

  const onRemoveSelectedCourtID = (item) => {
    const removed = eventinfo?.values?.timeSlotsCourt?.filter(
      (sitem) => sitem != item,
    );
    // setSelectedSlots(removed);
    eventinfo.setFieldValue('timeSlotsCourt', [...removed]);
  };

  const onRemoveSelectedTimeSlot = (item) => {
    const removed = eventinfo?.values?.timeSlots?.filter(
      (sitem) => sitem != item,
    );
    // setSelectedSlots(removed);
    eventinfo.setFieldValue('timeSlots', [...removed]);
  };

  const handleFileChangeEvent = (e) => {
    // console.log("e?.target?.files[0]: ", e?.target?.files[0]);
    setFile(e?.target?.files[0]);
    const image = e?.target?.files[0];
    const data = new FormData();
    data.append('image', image);
    const user = JSON.parse(getUser());
    fetchDataFromAPI('upload', 'post', data, user?.authToken)
      .then((res) => {
        console.log('res: ', res?.data);
        setURLEvent(res?.data);
      })
      .catch((error) => {
        console.log('error: ', error);
      });
  };

  const onSelectTCourtID = (e) => {
    if (e?.target?.value) {
      eventinfo?.setFieldValue('timeSlotsCourt', [
        ...eventinfo?.values?.timeSlotsCourt,
        e?.target?.value,
      ]);
    }
  };

  const onSelectTimeSlot = (e) => {
    if (e?.target?.value) {
      eventinfo?.setFieldValue('timeSlots', [
        ...eventinfo?.values?.timeSlots,
        e?.target?.value,
      ]);
    }
  };
  // Check if any event in the event array has a matching court ID
  const isCourtIdMatched = courtData?.event?.some((event) =>
    event?.court_id?.some((court) => court?._id === court_id),
  );

  const selectedDate = eventinfo?.values?.date;

  // Filter courtData based on the selected date
  const matchingEvents = courtData?.event?.filter(
    (item) =>
      new Date(item.date).toDateString() ===
      new Date(selectedDate).toDateString(),
  );
  console.log('matchingEvents', matchingEvents);

  // Get the combined timeSlot IDs for the matching events
  const combinedTimeSlots = [
    ...new Set(matchingEvents?.flatMap((item) => item?.timeSlot)),
  ];

  console.log('combinedTimeSlots: ', combinedTimeSlots);

  return (
    <>
      {loading && <Processing />}

      <Modal size="lg" show={show} centered onHide={handleClose}>
        <Modal.Header closeButton closeVariant="white" className=" border-0">
          <Modal.Title>{data ? 'Edit Event' : 'Add Event'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="card">
              <div className="card-body">
                <div className="d-flex flex-column  align-items-center">
                  <img
                    src={urlEvent ? urlEvent : data?.image}
                    className="rounded-circle avatar"
                    width={100}
                    height={100}
                  />
                  {/* {isEditable && ( */}
                  <div
                    className="icon-style disabled "
                    onClick={() => fileInputRef?.current?.click()}>
                    <i class="bi bi-pencil-square"></i> Add
                  </div>
                  {/* )} */}
                  <input
                    ref={fileInputRef}
                    accept="image/*"
                    className="d-none"
                    type="file"
                    onChange={handleFileChangeEvent}
                  />
                </div>
                <div className="d-flex flex-column mt-3">
                  <label className="category_label">Title</label>
                  <input
                    type="text"
                    name="title"
                    className="form form-control bg-transparent text-black"
                    value={eventinfo.values.title}
                    onChange={(e) => {
                      eventinfo.setFieldValue(
                        'title',
                        e.target.value?.trimStart(),
                      );
                    }}
                  />
                  {eventinfo.touched.title && eventinfo.errors.title && (
                    <div className="text-danger">{eventinfo.errors.title}</div>
                  )}
                </div>

                <div className="d-flex flex-column mt-3">
                  <label className="category_label">Info</label>
                  <ReactQuill
                    theme="snow"
                    value={eventinfo.values.info}
                    onChange={handleEditorChangeEvent}
                    style={{
                      height: 200,
                      color: '#000000',
                      marginBottom: 50,
                    }}
                  />
                  {eventinfo.touched.info && eventinfo.errors.info && (
                    <div className="text-danger">{eventinfo.errors.info}</div>
                  )}
                </div>

                <div className="d-flex flex-column mt-3">
                  <label className="category_label">Dates</label>
                  <input
                    type="date"
                    name="date"
                    className="form form-control bg-transparent text-black"
                    value={eventinfo.values.date}
                    onChange={(e) => {
                      eventinfo.setFieldValue('date', e.target.value);
                    }}
                    min={moment().format('YYYY-MM-DD')}
                  />

                  {eventinfo.touched.date && eventinfo.errors.date && (
                    <div className="text-danger">{eventinfo.errors.date}</div>
                  )}
                </div>

                <div className="d-flex flex-column mt-3">
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label="On court event (Sign up via timeslot)"
                    checked={eventinfo.values.courtEvent}
                    onChange={(e) =>
                      eventinfo.setFieldValue('courtEvent', e.target.checked)
                    }
                    className="custom-switch"
                    style={{marginTop: '20px'}} // Add some margin if needed
                  />
                </div>

                <div className="d-flex flex-column mt-3">
                  <label className="category_label">Show at courts</label>
                  <select
                    className="form form-select"
                    value={''}
                    // disabled={!eventinfo?.values.timeSlotsCourt}
                    onChange={onSelectTCourtID}>
                    <option>Select Show at courts</option>
                    {courtAllData
                      ?.filter((item) => item?._id !== court_id)
                      ?.map((item) => (
                        <option
                          value={item?._id}
                          disabled={
                            eventinfo.values.timeSlotsCourt?.filter(
                              (sitem) => sitem == item?._id,
                            )?.length > 0
                          }>
                          {item?.title} - {item?.subTitle}
                        </option>
                      ))}
                  </select>
                  <div className="d-flex align-items-center mt-3 gap-3">
                    {!data && (
                      <div className="card border-0 ">
                        <div className=" align-items-center justify-content-center gap-3 p-2">
                          <p className="m-0">
                            {matchedObject?.title} - {matchedObject?.subTitle}
                          </p>
                        </div>
                      </div>
                    )}

                    {eventinfo?.values?.timeSlotsCourt?.length > 0
                      ? eventinfo?.values?.timeSlotsCourt
                          // Sort the array so that the item matching courtData._id comes first
                          .sort((a, b) =>
                            a === courtData?._id
                              ? -1
                              : b === courtData?._id
                              ? 1
                              : 0,
                          )
                          .map((sitem) => (
                            <div className="card border-0" key={sitem}>
                              <div className="d-flex align-items-center justify-content-center gap-3 p-2">
                                <p className="m-0">
                                  {courtAllData?.find(
                                    (item) => item?._id === sitem,
                                  )?.title +
                                    ' - ' +
                                    courtAllData?.find(
                                      (item) => item?._id === sitem,
                                    )?.subTitle}
                                </p>
                                {sitem !== courtData?._id ? (
                                  <i
                                    className="bi bi-x-lg text-danger"
                                    role="button"
                                    onClick={() =>
                                      onRemoveSelectedCourtID(sitem)
                                    }></i>
                                ) : null}
                              </div>
                            </div>
                          ))
                      : null}
                  </div>
                </div>

                <div className="d-flex flex-column mt-3">
                  <label className="category_label">Timeslots</label>
                  <select
                    className="form form-select"
                    value={''}
                    // disabled={!eventinfo?.values?.courtEvent}
                    onChange={onSelectTimeSlot}>
                    <option>Select TimeSlots</option>
                    {timeSlots
                      ?.filter((item) => !combinedTimeSlots.includes(item?._id))
                      ?.map((item) => (
                        <option
                          value={item?._id}
                          disabled={
                            eventinfo.values.timeSlots?.filter(
                              (sitem) => sitem == item?._id,
                            )?.length > 0
                          }>
                          {item?.start_time + ' - ' + item?.end_time}
                        </option>
                      ))}
                  </select>
                  {eventinfo.touched.timeSlots &&
                    eventinfo.errors.timeSlots && (
                      <div className="text-danger">
                        {eventinfo.errors.timeSlots}
                      </div>
                    )}

                  <div className="d-flex align-items-center mt-3 gap-3">
                    {eventinfo.values.timeSlots?.length > 0
                      ? eventinfo.values.timeSlots?.map((sitem) => (
                          <div className="card border-0">
                            <div className="d-flex align-items-center justify-content-center gap-3 p-2">
                              <p className="m-0">
                                {timeSlots?.filter(
                                  (item) => item?._id == sitem,
                                )[0]?.start_time +
                                  ' - ' +
                                  timeSlots?.filter(
                                    (item) => item?._id == sitem,
                                  )[0]?.end_time}
                              </p>
                              <i
                                className="bi bi-x-lg text-danger"
                                role="button"
                                onClick={() =>
                                  onRemoveSelectedTimeSlot(sitem)
                                }></i>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>

                <div className="d-flex flex-column mt-3">
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label="3x3event (send webhook)"
                    className="custom-switch"
                    checked={eventinfo.values.sendWebook}
                    onChange={(e) =>
                      eventinfo.setFieldValue('sendWebook', e.target.checked)
                    }
                    style={{marginTop: '20px'}} // Add some margin if needed
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              handleClose();

              eventinfo.resetForm();
            }}>
            Close
          </Button>
          {data ? (
            <Button
              variant="secondary"
              onClick={() => {
                eventinfo.handleSubmit();
              }}>
              Edit
            </Button>
          ) : (
            <Button
              variant="secondary"
              onClick={() => {
                eventinfo.handleSubmit();
              }}>
              Add
            </Button>
          )}

          {/* <Button variant="danger" onClick={onDeleteCourt}>
          Delete
        </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddEvents;
