import React from 'react';
import './assets/css/style.css';
import routes from './routes/routes';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import Login from './pages/Login/Login';
import Error from './pages/Error/Error';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from 'react-toastify';
import Sidebar from './components/sidebar/sidebar';
import constants from './utils/constants';

const App = () => {
  const currentPath = useLocation();
  const USER = localStorage.getItem(constants.USER);
  return (
    <>
      {USER && currentPath.pathname !== '/404' && <Sidebar />}
      <ToastContainer />
      <Routes>
        <Route path="/login" element={<Login />} />
        {routes.map(({path, element}, index) => (
          <Route key={index} path={path} element={element} />
        ))}
        <Route path="/404" element={<Error />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </>
  );
};

export default App;
