import React, { useEffect, useState } from "react";
import { fetchDataFromAPI } from "../../utils/api";
import { getUser } from "../../utils/constants";
import Delete from "../../assets/svgs/delete";
import { BsFillEyeFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Processing from "../../components/Processing/Processing";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

const Members = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  console.log("users", users);
  const [show, setShow] = useState(false);
  const [deleteID, setDeleteID] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();
  useEffect(() => {
    getMembers();
  }, []);

  const getMembers = async () => {
    setLoading(true);
    const user = JSON.parse(getUser());
    fetchDataFromAPI("users", "get", "", user?.authToken)
      .then((res) => {
        console.log("users res: ", res?.data);
        setUsers(res?.data?.reverse());
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("users error: ", error);
      });
  };

  const onDeleteMember = () => {
    const user = JSON.parse(getUser());
    fetchDataFromAPI(`users?id=${deleteID}`, "delete", "", user?.authToken)
      .then((res) => {
        toast.success("Member deleted successfully ✅", {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-success text-white",
        });
        getMembers();
        handleClose();
        console.log("Member delete res: ", res?.data);
      })
      .catch((error) => {
        toast.error("Error while deleting Member", {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-danger text-white",
        });
        handleClose();
        console.log("Member delete error: ", error);
      });
  };

  return (
    <>
      <div className="dsa__users">
        {loading && <Processing />}
        <div className="d-flex align-items-center justify-content-between">
          <div className="title">Members</div>
        </div>

        {/* table here */}
        <div className="user__list mt-3 table-responsive">
          <table border={0} cellSpacing={0} className="user__table text-nowrap">
            <thead>
              <tr>
                <th>Image</th>
                <th>Username</th>
                <th>Email</th>

                {/* 7 - 11 - 2024 */}
                <th>3x3 Phone number</th>
                <th>Level</th>
                <th>Signature Move</th>
                <th>Account Type</th>
                <th className="text-end">Actions</th>
              </tr>
            </thead>

            <tbody>
              {users?.length > 0 ? (
                users?.map((user) => (
                  <tr key={user?._id}>
                    <td className="col-2">
                      <img
                        src={user?.profile}
                        alt="member-pic"
                        width={50}
                        height={50}
                        className="court_image"
                      />
                    </td>
                    <td className="col-2">
                      <p>{user?.username}</p>
                    </td>
                    <td className="col-3">
                      <p className="note">{user?.email || "-"}</p>
                    </td>
                    {/* 7 - 11 - 2024 */}

                    <td className="col-3">
                      <p className="note">
                        {user?.phone
                          ? user?.code.match(/\+\d+/)[0] + user?.phone
                          : "-"}
                      </p>
                    </td>
                    <td>
                      <span className="badge bg-info">
                        {user?.level?.level_name || "-"}
                      </span>
                    </td>
                    <td>
                      <span className="badge bg-warning">
                        {user?.signature_move || "-"}
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          user?.social_type === 0
                            ? "badge bg-info"
                            : user?.social_type === 1
                            ? "badge bg-primary"
                            : "badge bg-dark"
                        }
                      >
                        {user?.social_type === 0
                          ? "Google"
                          : user?.social_type === 1
                          ? "FaceBook"
                          : "Apple"}
                      </span>
                    </td>
                    <td className="col-2 text-end align-middle h-100">
                      <div className="d-flex gap-2  justify-content-end">
                        <span
                          onClick={() =>
                            navigate(`/members/${user?._id}`, {
                              state: { user: user },
                            })
                          }
                        >
                          <BsFillEyeFill />
                        </span>
                        {/* <div className="edit_button">
                            <Edit />
                          </div> */}
                        <div
                          className="delete_button"
                          onClick={() => {
                            setDeleteID(user?._id);
                            handleShow();
                          }}
                        >
                          <Delete />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="text-center text-dark h3 p-5" colSpan={12}>
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header
          closeButton
          closeVariant="white"
          className="primary_bg border-0 text-white"
        >
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body className="primary_bg text-white">
          Are you sure you want to delete this Member?
        </Modal.Body>
        <Modal.Footer className="primary_bg border-0">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={onDeleteMember}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Members;
