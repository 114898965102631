import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import * as Yup from 'yup';
import {fetchDataFromAPI} from '../../utils/api';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {getUser} from '../../utils/constants';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';

const AddSubAdminModel = ({onClose, show, data}) => {
  const [file, setFile] = useState(null);
  //   const [fileError, setFileError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [imgURL, setImgURL] = useState('');
  const [inputKey, setInputKey] = useState('');
  const [courtAllData, setAllCourt] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (data) {
      //   setLoading(true);
      formik.setFieldValue('first_name', data?.first_name);
      formik.setFieldValue('last_name', data?.last_name);
      formik.setFieldValue('email', data?.email);
      formik.setFieldValue('password', data?.password);
      formik.setFieldValue('courts', data?.courts);

      //   setLoading(false);
    }
  }, [data]);

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('Please enter first name'),
    last_name: Yup.string().required('Please enter last name'),
    email: Yup.string().required('Please enter email'),
    password: Yup.string().required('Please enter password'),
    courts: Yup.array()
      .min(1, 'Please select courts')
      .required('Please select courts'),
  });

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      courts: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      //   if (!file) {
      //     setFileError("Please select image for court");
      //     return;
      //   }
      console.log('values: ', values);
      const body = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        password: values.password,
        profile: imgURL,
        courts: values.courts,
        type: 2, //  admin
        ...(data && {id: data._id}),
      };

      console.log(body);
      if (!imgURL) {
        delete body.profile;
      }
      const user = JSON.parse(getUser());

      if (data) {
        fetchDataFromAPI('admin/profile-update', 'post', body, user?.authToken)
          .then((res) => {
            toast.success('Admin updated successfully ✅', {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });
            console.log('add Admin res: ', res?.data);
            onClose();
            resetForm();
          })
          .catch((error) => {
            toast.error('Error while creating Admin', {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            });
            console.log('add Admin error: ', error);
          });
      } else {
        fetchDataFromAPI('admin/register', 'post', body, user?.authToken)
          .then((res) => {
            toast.success('Admin created successfully ✅', {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });
            console.log('add Admin res: ', res?.data);
            onClose();
            resetForm();
          })
          .catch((error) => {
            toast.error('Error while creating Admin', {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            });
            console.log('add Admin error: ', error);
          });
      }
    },
  });

  function functionThatResetsTheFileInput() {
    let randomString = Math.random().toString(36);
    setInputKey(randomString);
  }

  const resetForm = () => {
    formik.resetForm();
    setFile(null);
    // setFileError("");
    functionThatResetsTheFileInput();
    setImgURL('');
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    getCourt();
  }, [show]);

  const onRemoveSelectedCourtID = (item) => {
    const removed = formik?.values?.courts?.filter((sitem) => sitem != item);
    // setSelectedSlots(removed);
    formik.setFieldValue('courts', [...removed]);
  };

  const onSelectTCourtID = (e) => {
    if (e?.target?.value) {
      formik?.setFieldValue('courts', [
        ...formik?.values?.courts,
        e?.target?.value,
      ]);
    }
  };

  const getCourt = () => {
    const user = JSON.parse(getUser());

    fetchDataFromAPI('Court/names', 'get', ' ', user?.authToken)
      .then((res) => {
        setAllCourt(res?.data);
        console.log('res ---->', res);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          onClose();
          resetForm();
          setImgURL('');
        }}
        centered>
        <Modal.Header closeButton>
          {data ? (
            <Modal.Title>Edit Court Admin</Modal.Title>
          ) : (
            <Modal.Title>Add Court Admin</Modal.Title>
          )}
        </Modal.Header>

        <Modal.Body>
          <div className="d-flex flex-column mt-3">
            <label className="category_label">First Name</label>
            <input
              type="text"
              name="first_name"
              placeholder="Enter first name"
              className="form form-control bg-transparent text-black add_inputs"
              //   {...formik.getFieldProps("first_name")}
              value={formik.values.first_name}
              onChange={(e) => {
                formik.setFieldValue('first_name', e.target.value?.trimStart());
              }}
            />
            {formik.touched.first_name && formik.errors.first_name && (
              <div className="text-danger">{formik.errors.first_name}</div>
            )}
          </div>
          <div className="d-flex flex-column mt-3">
            <label className="category_label">Last Name</label>
            <input
              type="text"
              name="last_name"
              // {...formik.getFieldProps("email")}
              value={formik.values.last_name}
              onChange={(e) => {
                formik.setFieldValue('last_name', e.target.value?.trimStart());
              }}
              placeholder="Enter last name"
              className="form form-control bg-transparent text-black add_inputs"
            />
            {formik.touched.last_name && formik.errors.last_name && (
              <div className="text-danger">{formik.errors.last_name}</div>
            )}
          </div>
          <div className="d-flex flex-column mt-3">
            <label className="category_label">Email</label>
            <input
              type="text"
              name="email"
              // {...formik.getFieldProps("email")}
              value={formik.values.email}
              onChange={(e) => {
                formik.setFieldValue('email', e.target.value?.trimStart());
              }}
              placeholder="Enter email"
              className="form form-control bg-transparent text-black add_inputs"
            />
            {formik.touched.email && formik.errors.email && (
              <div className="text-danger">{formik.errors.email}</div>
            )}
          </div>

          <div className="d-flex flex-column mt-3">
            <label className="category_label">Password</label>
            <div className="password-input">
              <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                placeholder="Enter password"
                className="form form-control bg-transparent text-black type_password"
                // {...formik.getFieldProps("password")}
                value={formik.values.password}
                onChange={(e) => {
                  formik.setFieldValue('password', e.target.value?.trim());
                }}
              />
              <div
                className="eye-icon text-secondary"
                onClick={togglePasswordVisibility}>
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </div>
            </div>
            {formik.touched.password && formik.errors.password && (
              <div className="text-danger">{formik.errors.password}</div>
            )}
          </div>

          <div className="d-flex flex-column mt-3">
            <label className="category_label">Show at courts</label>
            <select
              className="form form-select"
              value={''}
              // disabled={!eventinfo?.values.timeSlotsCourt}
              onChange={onSelectTCourtID}>
              <option>Select Show at courts</option>
              {courtAllData?.map((item) => (
                <option
                  value={item?._id}
                  disabled={
                    formik.values.courts?.filter((sitem) => sitem == item?._id)
                      ?.length > 0
                  }>
                  {item?.title} - {item?.subTitle}
                </option>
              ))}
            </select>
            <div className="d-flex align-items-center mt-3 gap-3">
              {formik?.values?.courts?.length > 0
                ? formik?.values?.courts
                    // Sort the array so that the item matching courtData._id comes first

                    .map((sitem) => (
                      <div className="card border-0" key={sitem}>
                        <div className="d-flex align-items-center justify-content-center gap-3 p-2">
                          <p className="m-0">
                            {courtAllData?.find((item) => item?._id === sitem)
                              ?.title +
                              ' - ' +
                              courtAllData?.find((item) => item?._id === sitem)
                                ?.subTitle}
                          </p>
                          {sitem ? (
                            <i
                              className="bi bi-x-lg text-danger"
                              role="button"
                              onClick={() =>
                                onRemoveSelectedCourtID(sitem)
                              }></i>
                          ) : null}
                        </div>
                      </div>
                    ))
                : null}
            </div>
            {formik.touched.courts && formik.errors.courts && (
              <div className="text-danger">{formik.errors.courts}</div>
            )}
          </div>

          <div className="d-flex flex-column mt-3">
            <label className="category_label">Image</label>
            <input
              key={inputKey || ''}
              type="file"
              accept="image/*"
              name="image"
              placeholder="Pick Image for Court"
              className="form form-control bg-transparent text-black add_inputs"
              // {...formik.getFieldProps("password")}
              //   value={file}
              onChange={(e) => {
                setFile(e.target.files[0]);
                const image = e?.target?.files[0];
                const data = new FormData();
                data.append('image', image);
                const user = JSON.parse(getUser());
                fetchDataFromAPI('upload', 'post', data, user?.authToken)
                  .then((res) => {
                    console.log('res: ', res?.data);
                    setImgURL(res?.data);
                  })
                  .catch((error) => {
                    console.log('error: ', error);
                  });
                // setFileError("");
              }}
            />
            {/* {fileError && <div className="text-danger">{fileError}</div>} */}
          </div>
          {file ? (
            <img
              src={imgURL} // Selected file's image
              alt="selected-image"
              className="rounded mt-3"
              width={50}
              height={50}
            />
          ) : data?.profile ? (
            <img
              src={data.profile} // Existing profile image
              alt="profile-image"
              className="rounded mt-3"
              width={50}
              height={50}
            />
          ) : (
            <p>No image available</p> // Fallback if neither exists
          )}
        </Modal.Body>

        <Modal.Footer>
          <button
            variant="secondary"
            className="btn btn-primary"
            //   data-bs-dismiss="modal"
            onClick={resetForm}>
            Clear
          </button>
          <button
            type="button"
            onClick={formik.handleSubmit}
            className="btn secondary_bg advice__category text-white">
            {data ? 'Update' : 'Add'}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddSubAdminModel;
